import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  MenuItemOption,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FinalizeBehavior,
  PERMISSIONS,
  Report,
  ReportStatus,
  User,
} from '@piccolohealth/echo-common';
import { P } from '@piccolohealth/util';
import _ from 'lodash';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { DistributionOnFinalizeSummary } from '../../../features/distributions/components/DistributionOnFinalizeSummary';
import { useUpdateReportStatusMutation } from '../../../graphql/hooks/useUpdateReportStatusMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { usePermission } from '../../../hooks/usePermission';
import { validateSchema } from '../../../utils/validation';
import { HookedSubmitButton } from '../../forms/hookform/HookedSubmitButton';
import { createModal, showModal } from '../../generic/Modal';
import { awaitingReviewErrorSchema } from './AwaitingReviewMenuItemOption';
import { FinalizeBehaviorSummary } from './FinalizeBehaviorSummary';
import { ReportStatusIssues } from './ReportStatusIssues';

export const finalizeErrorsSchema = awaitingReviewErrorSchema;

export const finalizeWarningsSchema = (user: User) =>
  Yup.object().shape({
    cardiologist: Yup.object()
      .shape({
        value: Yup.array().nullable().min(1, 'No reporting cardiologist selected'),
      })
      .test('cardiologistPresent', ({ value }, context) => {
        const values = value ?? [];

        if (_.isEmpty(values) || _.includes(values, user.name)) {
          return true;
        }

        return context.createError({
          message: `You (${user.name}) are not in the list of assigned cardiologists (${values.join(
            ',',
          )})`,
          path: 'cardiologist',
        });
      })
      .nullable(),
  });

interface FormValues {
  finalizeBehavior: FinalizeBehavior;
  nextReportId?: string | null;
}

interface FinalizeModalProps {
  report: Report;
}

const FinalizeModal = createModal<FinalizeModalProps>((props) => {
  const { modal, report } = props;

  const { user, organization, successToast, errorToast } = useAppContext();
  const mutation = useUpdateReportStatusMutation();
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    finalizeBehavior: Yup.string().required(),
    nextReportId: Yup.string().nullable(),
  });

  const methods = useForm<FormValues>({
    defaultValues: {
      finalizeBehavior: user.settings.finalizeBehavior,
      nextReportId: null,
    },
    resolver: yupResolver(validationSchema),
  });

  const onFinalizeBehavior = React.useCallback(
    (behavior: FinalizeBehavior, nextReportId?: string | null) => {
      switch (behavior) {
        case FinalizeBehavior.NextReport:
          return navigate(`/organizations/${organization.id}/reports/${nextReportId}`);
        case FinalizeBehavior.Reports:
          return navigate(`/organizations/${organization.id}/reports`);
        case FinalizeBehavior.Noop:
          return;
      }
    },
    [navigate, organization.id],
  );

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutation
        .mutateAsync({
          organizationId: organization.id,
          reportId: report.id,
          updateReportStatusRequest: {
            status: ReportStatus.Finalized,
          },
        })
        .then(() => {
          successToast('Report finalized');
          onFinalizeBehavior(values.finalizeBehavior, values.nextReportId);
        })
        .catch(() => {
          errorToast('Error updating report status');
        });

      modal.hide();
    },
    [mutation, organization.id, report.id, modal, successToast, onFinalizeBehavior, errorToast],
  );

  const { errors, warnings } = React.useMemo(() => {
    const values = P.keyBy(report.variables, (variable) => variable.alias as string) ?? {};

    return {
      errors: validateSchema(finalizeErrorsSchema, values) ?? [],
      warnings: validateSchema(finalizeWarningsSchema(user), values) ?? [],
    };
  }, [report.variables, user]);

  const isDisabled = !P.isEmpty(errors);

  return (
    <Modal
      isOpen={modal.visible}
      onClose={modal.hide}
      onCloseComplete={modal.remove}
      size="2xl"
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalHeader>
              <Flex align="start">
                <Box>
                  <Heading size="md">Finalize report</Heading>
                  <Text fontSize="md" fontWeight="normal" color="secondary" mt={2}>
                    Finalize and distribute this report, generating PDFs
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position="unset" top="unset" right="unset" />
              </Flex>
            </ModalHeader>
            <ModalBody>
              <Stack spacing={4}>
                <ReportStatusIssues warnings={warnings} errors={errors} />
                <DistributionOnFinalizeSummary reportId={report.id} />
                <FinalizeBehaviorSummary report={report} />
                <Text fontSize="sm">Are you sure you want to continue?</Text>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup size="sm">
                <Button onClick={modal.hide}>Close</Button>
                <Tooltip
                  label="All errors must be fixed before proceeding"
                  isDisabled={!isDisabled}
                >
                  <Box>
                    <HookedSubmitButton
                      isDisabled={isDisabled}
                      colorScheme="purple"
                      data-pw="reportStatusUpdateModalSubmitButton"
                    >
                      Finalize
                    </HookedSubmitButton>
                  </Box>
                </Tooltip>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});

export const FinalizeMenuItemOption = (props: { report: Report }) => {
  const { report } = props;
  const hasPermission = usePermission(PERMISSIONS.reportsFinalize).value;
  const isChecked = report.status === ReportStatus.Finalized;
  const isDisabled = !hasPermission || isChecked;

  return (
    <>
      <MenuItemOption
        onClick={() => showModal(FinalizeModal, { report })}
        isDisabled={isDisabled}
        isChecked={isChecked}
        data-pw={`reportStatusUpdateMenuItemOption-${ReportStatus.Finalized}`}
      >
        Finalized
      </MenuItemOption>
    </>
  );
};
